import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import Download from '~/components/icons/Download'
import Link from '~/components/Link'
import LinkButton from '~/components/LinkButton'
import { medias } from '~/constants/medias'
import { ROUTES } from '~/constants/routes'
import { useLocale } from '~/hooks/useLocale'
import type { Locale } from '~/i18n/config'
import { cn } from '~/utils/cn'
import { trackEvent, TrackingEventName } from '~/utils/tracking'
import type { SlotPickContext } from '../SlotPicker.types'

const CHECK_UP_MEDIAS_FILES = (t: TFunction, locale: Locale) => {
  return [
    {
      label: t('common:examsList'),
      path: medias.pdf.exams[locale],
      clickEventName: 'DOWNLOAD_EXAMS_LIST_CLICK',
      testId: 'downloadExamsList'
    },
    {
      label: t('common:biomarkersList'),
      path: medias.pdf.bio[locale],
      clickEventName: 'DOWNLOAD_BIOMARKERS_LIST_CLICK',
      testId: 'downloadBiomarkersList'
    }
  ] as const
}

export type CheckUpContentsDetailsProps = {
  context: SlotPickContext
  hasBuyButton?: boolean
  className?: string
}

export const CheckUpContentsDetails = ({
  context,
  hasBuyButton = false,
  className = ''
}: CheckUpContentsDetailsProps) => {
  const locale = useLocale()
  const { t } = useTranslation()

  const handleDownloadClick = (eventName: TrackingEventName) => {
    trackEvent(eventName, {
      page: context
    })
  }

  return (
    <div className={cn('flex flex-col border-t border-black/10', className)}>
      <ul className="py-6 flex w-full flex-col gap-y-4 sm:items-center lg:items-start px-8">
        {CHECK_UP_MEDIAS_FILES(t, locale).map((file) => {
          return (
            <li key={file.label} className="flex">
              <Link
                underline="hover"
                className="flex items-center gap-x-1"
                to={file.path}
                testId={file.testId}
                target="_blank"
                onClick={() => {
                  return handleDownloadClick(file.clickEventName)
                }}
              >
                <span>{file.label}</span>
                <Download width={16} height={16} />
              </Link>
            </li>
          )
        })}
      </ul>
      <div className="w-full px-8 py-6 flex gap-x-3 max-lg:flex-col max-lg:gap-y-4 border-t-1 border-black/10">
        <LinkButton
          to={ROUTES.contactUs}
          size="md"
          fullWidth
          color="secondary"
          rounded="full"
          testId="question"
        >
          {t('common:anotherQuestion')}
        </LinkButton>
        {hasBuyButton ? (
          <LinkButton
            fullWidth
            rounded="full"
            testId="buy"
            to={ROUTES.gift_beneficiary}
          >
            {t('common:buy')}
          </LinkButton>
        ) : null}
      </div>
    </div>
  )
}

export default CheckUpContentsDetails
